































































import { Component, Vue } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import { showNotification } from '@/services/NotificationService'

@Component({
  components: {
    Button
  }
})
export default class Info extends Vue {
  sending = false
  name = ''
  mail = ''
  phone = ''
  text = ''

  async sendMessage() {
    this.sending = true
    try {
      await new Promise((f) => setTimeout(f, 1500))
      showNotification({
        title: 'Nachricht versendet',
        text: 'Ihre Nachricht wurde versendet.',
        type: 'success',
        timeout: 10
      })
    } catch (ex) {
      console.error(ex)
    } finally {
      this.sending = false
    }
  }
}

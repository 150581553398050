













































































































import { Component, Vue } from 'vue-property-decorator'
import Content from '@/components/Content.vue'
import Header from '@/components/Header.vue'
import Contact from '@/components/Contact.vue'

@Component({
  components: {
    Contact,
    Header,
    Content
  }
})
export default class Info extends Vue {
}
